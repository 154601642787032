import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserData } from "../../Shared/Types/Types";

interface UserState {
  data: UserData | null;
}

const initialState: UserState = {
  data: null,
};

const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData | null>) => {
      state.data = action.payload;
    },
  },
});

export const { setUserData } = userSlice.actions;

export default userSlice.reducer;
