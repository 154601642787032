import { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Loader from "./components/Loader/loader";
import BlockSite from "./Pages/MainPages/BlockSitePage/BlockSite";
import { GetDomainDetails } from "./util/CallFunctions/GetWebSiteInfo/GetDomainDetails";
import {
  ADDRESS_PATH,
  BLOCK_SITE_PATH,
  DETAIL_MENU_PATH,
  HOME_PATH,
  INTRO_PATH,
  LOGIN_PATH,
  OTP_PATH,
  ROOT_PATH,
  SETTINGS_WALK_PATH,
  SIGNUP_PATH,
  SIGNUP_SUCCESS_PATH,
} from "./Shared/enums/paths";

// Lazy-loaded components
const LoginPage = lazy(
  () => import("./Pages/OnBoardingPages/Auth/LoginPage/loginPage")
);
const Otp = lazy(() => import("./Pages/OnBoardingPages/Auth/Otp/Otp"));
const SignUp = lazy(
  () => import("./Pages/OnBoardingPages/Auth/SignUpProcess/SignUp")
);
const Home = lazy(() => import("./Pages/MainPages/Home/Home"));
const DetailMenu = lazy(
  () => import("./Pages/MainPages/DetailMenu/DetailMenu")
);
const SignUpSuccess = lazy(
  () => import("./Pages/MainPages/SignUpSuccess/SignUpSuccess")
);
const WelcomeScreen = lazy(
  () => import("./Pages/OnBoardingPages/WelcomeScreen/WelcomeScreen")
);
const Intro = lazy(() => import("./Pages/OnBoardingPages/intro/Intro"));
const SettingsWalk = lazy(
  () => import("./Pages/OnBoardingPages/SettingsWalk/SettingsWalk")
);
const Address = lazy(
  () => import("./Pages/OnBoardingPages/Auth/SignUpProcess/Address")
);

function App() {
  const dispatch = useDispatch();
  const [siteNotIntegrated, setSiteNotIntegrated] = useState(false);

  useEffect(() => {
    GetDomainDetails(dispatch, setSiteNotIntegrated);
  }, []);

  return (
    <>
      <BrowserRouter>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <Loader />
            </div>
          }
        >
          <Routes>
            <Route
              path={ROOT_PATH}
              element={siteNotIntegrated ? <BlockSite /> : <WelcomeScreen />}
            />
            {/* <Route path={INTRO_PATH} element={<Intro />} /> */}
            <Route path={SETTINGS_WALK_PATH} element={<SettingsWalk />} />
            <Route path={LOGIN_PATH} element={<LoginPage />} />
            <Route path={OTP_PATH} element={<Otp />} />
            <Route path={SIGNUP_PATH} element={<SignUp />} />
            <Route path={HOME_PATH} element={<Home />} />
            <Route path={DETAIL_MENU_PATH} element={<DetailMenu />} />
            <Route path={BLOCK_SITE_PATH} element={<BlockSite />} />
            <Route path={SIGNUP_SUCCESS_PATH} element={<SignUpSuccess />} />
            <Route path={ADDRESS_PATH} element={<Address />} />
            <Route path="*" element={<Navigate to={ROOT_PATH} />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
