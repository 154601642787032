import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "../Onboarding/Auth";
import isSessionFetched from "../Home/isSessionFetched";
import parentDataType from "../ParentData/ParentDataType";
import brandReducer from "../BrandDeatils/BrandDetails";
import userReducer from "../UserDetails/userDetails";

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    session: isSessionFetched,
    parent: parentDataType,
    brand: brandReducer,
    user: userReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
