export const fetchWrapper = {
  get,
  post,
  put,
  delete: _delete,
  patch,
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;

async function get(
  url: string,
  token?: string | null | undefined,
): Promise<any> {
  const requestOptions: RequestInit = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(baseUrl + url, requestOptions);
  return handleResponse(response);
}

async function post(url: string, body: any, Token?: string): Promise<any> {
  const token = Token;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const requestOptions: RequestInit = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(baseUrl + url, requestOptions);
  return handleResponse(response);
}

async function put(url: string, body: any): Promise<any> {
  const requestOptions: RequestInit = {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(body),
  };
  const response = await fetch(baseUrl + url, requestOptions);
  return handleResponse(response);
}

async function patch(url: string, body: any, Token: string): Promise<any> {
  const token = Token;
  const headers: Record<string, string> = {
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const requestOptions: RequestInit = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(baseUrl + url, requestOptions);
  //  if (response.ok) {
  //   await handleSessionUpdate(BrandDetails);
  // }
  return handleResponse(response);
}

async function _delete(url: string): Promise<any> {
  const requestOptions: RequestInit = {
    method: "DELETE",
  };
  const response = await fetch(baseUrl + url, requestOptions);
  0
  return handleResponse(response);
}

async function handleResponse(response: Response): Promise<any> {
  const text = await response.text();
  const data = text && JSON.parse(text);
  if (!response.ok) {
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}
