import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MyState {
  value: boolean;
  count: number;
}

const initialState: MyState = {
  value: false,
  count: 0,
};

const isSessionFetchedSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setisSessionFetched: (state, action: PayloadAction<boolean>) => {
      state.value = action.payload;
    },
    setSessionCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
  },
});

export const { setisSessionFetched, setSessionCount } =
  isSessionFetchedSlice.actions;
export default isSessionFetchedSlice.reducer;
