import Cookies from "js-cookie";
import { fetchWrapper } from "../../../api/FetchAPI/FetchAPI";
import CheckDomain from "../CheckParentDomain/CheckDomain";
import { SessionCheck } from "../../HandleBackendError/SessionCheck";
import { Dispatch } from "redux";
import { setBrandDeatils } from "../../../Redux/BrandDeatils/BrandDetails";

export const GetDomainDetails = async (
  dispatch: Dispatch,
  setSiteNotIntegrated: (data: boolean) => void
) => {
  const domainValue = CheckDomain();
  const brandIdFromCookie = Cookies.get(`${domainValue}`);
  try {
    const url = `brand/metadata/get?website=${domainValue}`;
    const response = await fetchWrapper.get(url);
    if (response) {
      if (!brandIdFromCookie) {
        const data = {
          domain: `${response.id}`,
          sessionFetch: false,
          // time: Date.now(),
        };
        Cookies.set(`${domainValue}`, JSON.stringify(data), {
          sameSite: "None",
          secure: true,
          domain: ".identitilabs.xyz",
        });
      }
      dispatch(setBrandDeatils(response));
    }
  } catch (error) {
    setSiteNotIntegrated(true);
    SessionCheck(error);
  }
};
