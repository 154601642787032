import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BrandDetails } from "../../Shared/Types/Types";

const initialState: BrandDetails = {
  id: "",
  imageURL: "",
  brandName: "",
  brandUrl: "",
  brandColor: "",
  whatsappMobile: "",
  whatsappText: "",
};

const BrandSlice = createSlice({
  name: "Brand",
  initialState,
  reducers: {
    setBrandDeatils(state, action: PayloadAction<BrandDetails>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { setBrandDeatils } = BrandSlice.actions;
export default BrandSlice.reducer;
