import { Container, Title } from "../../../Shared/Style/globalStyle";
const BlockSite = () => {
  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <img
          style={{
            width: "calc(100vh/3)",
            marginLeft: "calc(100vh/8)",
            height: "auto",
          }}
          src="https://super-x-prod-frontend.s3.ap-south-1.amazonaws.com/superxWidgetAssets/superX-Images/logosuper+(2).png"
          alt="welcome logo"
        />
        <Title
          style={{
            display: "flex",
            justifyContent: "center",
            width: "80%",
            textAlign: "center",
          }}
        >
          Apologies, but access to these sites is restricted to specific
          integrated brands only.
        </Title>
      </div>
    </Container>
  );
};
export default BlockSite;
