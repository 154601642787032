const CheckDomain = (): string => {
  const parentUrl = 
    window.location !== window.parent.location
      ? document.referrer
      : document.location.href;

  // Find the index of the first slash after the protocol
  const protocolEndIndex = parentUrl.indexOf("://");
  if (protocolEndIndex !== -1) {
    const firstSlashIndex = parentUrl.indexOf("/", protocolEndIndex + 3);
    if (firstSlashIndex !== -1) {
      return parentUrl.substring(protocolEndIndex + 3, firstSlashIndex);
    }
    return parentUrl.substring(protocolEndIndex + 3); // If no slash after the protocol, return the full URL without the protocol
  }
  return parentUrl; // If no protocol found, return the full URL
};

export default CheckDomain;
