// paths.ts

export const ROOT_PATH = "/";
export const INTRO_PATH = "intro";
export const SETTINGS_WALK_PATH = "settingswalk";
export const LOGIN_PATH = "login";
export const OTP_PATH = "otp";
export const SIGNUP_PATH = "signup";
export const HOME_PATH = "home";
export const DETAIL_MENU_PATH = "detailmenu";
export const BLOCK_SITE_PATH = "blocksite";
export const SIGNUP_SUCCESS_PATH = "signupsuccess";
export const ADDRESS_PATH = "address";
