import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Item {
  logourl: string;
  BrandName: string;
  BannerCard: {
    BannerTitle: string;
    BannerImage: string;
    BannerText: string;
  };
  CategoryName: {
    CardName: string;
    cardTitle: string;
    cardOffer: string;
    cardurl: string;
  }[];
}

interface ItemState {
  items: Item[];
}

const initialState: ItemState = {
  items: [],
};

const ParentDataType = createSlice({
  name: "items",
  initialState,
  reducers: {
    setItemsdata(state, action: PayloadAction<Item[]>) {
      state.items = action.payload;
    },
  },
});

export const { setItemsdata } = ParentDataType.actions;

export default ParentDataType.reducer;
