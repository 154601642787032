import Cookies from "js-cookie";

export enum Error {
  sessionExpiry = "Unauthorized",
  unauthorised = "unauthorized",
}

export const SessionCheck = (e?: any) => {
  if (e === Error.sessionExpiry || e === "Unauthorized") {
    Cookies.remove("J_G1ngW%", {
      sameSite: "None",
      secure: true,
      replace: true,
      domain: ".identitilabs.xyz",
    });
    Cookies.remove("Routes", {
      sameSite: "None",
      secure: true,
      domain: ".identitilabs.xyz",
    });
  }
};
